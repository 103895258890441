<template>
  <v-container>
    <v-sheet v-if="user">
      <v-breadcrumbs :items="[{
          text: 'Главная',
          href: '/',
          disabled: false,
          href: '/#/',
        },
        {
          text: 'Личный кабинет',
          disabled: true,
          href: '/#/profile/'+user.id,
        }]"></v-breadcrumbs>
      <CardUI>
        <template v-slot:body>
          <v-row align="center">
            <v-col md="4" lg="1" cols="3">
              <v-avatar color="white" size="96" style="border: 2px solid #0090ff;">
                <v-img :lazy-src="user.avatar_16" :src="user.avatar"></v-img>
              </v-avatar>
            </v-col>
            <v-col md="8" cols="9">
              <v-col class="py-0">

                <div style="font-size: 28px; font-weight: bold">
                  {{ user.last_name }}
                  {{ user.first_name }}
                  {{ user.third_name }}
                  <v-icon v-if="user.id === $store.state.user.currentUser.id" class="ml-4"
                          @click.stop="$store.dispatch('logout'), $router.push('/auth/login')">mdi-logout
                  </v-icon>
                </div>


                <div v-if="user.id === $store.state.user.currentUser.id || $store.state.user.currentUser.admin > 0"
                     style="color: gray">
                <span
                    v-if="user.role === 'visitor'">посетитель</span>
                  <span v-if="user.role === 'student'">студент гр. {{ user.student_group.name }}</span>
                  <span v-if="user.role === 'pupil'">учащийся</span>
                  <span v-if="user.role === 'employee'">сотрудник</span>
                  <span v-if="user.role === 'company'">партнер</span>
                </div>
                <div style="font-size: 12px">
                  был в сети
                  <span v-if="user.last_visit">
                  {{ $moment.utc(user.last_visit).fromNow() }}
                </span>
                  <span v-else>
                  давно
                </span>
                </div>
                <v-btn
                    v-if="$store.getters.checkPermission('can_manage_users') || user.id === $store.state.user.currentUser.id"
                    :to="{name: 'profile.edit', params: {id: user.id}}" class="mt-3" color="primary" outlined text
                    x-small>
                  <v-icon color="gray" left size="14">mdi-pencil</v-icon>
                  Редактировать профиль
                </v-btn>

              </v-col>

            </v-col>
            <v-spacer/>
            <v-col lg="2" cols="12">
              <v-btn v-if="user.id !== $store.state.user.currentUser.id" @click="$emit('chat', user.id)"
                     color="primary"
                     style="color: white">
                <v-icon left>mdi-chat</v-icon>
                Написать
              </v-btn>
            </v-col>

          </v-row>
        </template>
      </CardUI>
      <PermissionsComponent v-if="user.id > 0 && this.$store.getters.checkPermission('can_manage_users')"
                            :user_id="user.id"/>


      <div v-if="user.id === $store.state.user.currentUser.id" class="pa-2 pa-md-4">
        <h3 class="my-3">Мои дипломы</h3>
        <div class="row">
          <a target="_blank" :href="`https://api.student.surgu.ru/cert/${event.id}/${user.id}`" class="col-md-3" v-for="event in events" :key="event.id">
            <img width="100%" :src="`https://api.student.surgu.ru/cert/${event.id}/${user.id}`">
            <div class="text-center font-weight-bold text-decoration-none text-none">
              {{event.name}}
            </div>
          </a>
        </div>

      </div>

      <div v-if="$store.state.projects.userProjects.length" class="pa-2 pa-md-4">
        <h3 class="my-2">Проекты пользователя</h3>
        <v-row>
          <v-col v-for="project in $store.state.projects.userProjects" :key="project.id" cols="12" lg="6">
            <ProjectListItemComponent :project="project"/>
          </v-col>
        </v-row>
      </div>


    </v-sheet>
  </v-container>
</template>

<script>
import users from "@/api/users.js";
import analytics from "@/api/analytics.js";
import CardUI from "@/components/UI/CardUI.vue";
import PermissionsComponent from "@/components/Profile/PermissionsComponent.vue";
import ProjectListItemComponent from "@/components/Projects/ProjectListItemComponent";

export default {
  name: "ProfileComponent",
  components: {CardUI, PermissionsComponent, ProjectListItemComponent},
  data() {
    return {
      user: undefined,
      transactions: [],
      events: []
    }
  },
  mounted() {
    if (!this.$store.getters.isAuth) {
      this.$router.push('/auth/login');
      return;
    }

    users.events().then((r) => {
      this.events = r.data;
    });

    analytics.openProfilePage(this.$store.state.user.currentUser.id);
    users.item({id: this.$route.params.id}).then((r) => {
      this.user = r.data;
      this.$store.dispatch('getProjectsByUser', {user_id: this.$route.params.id})
    });


    users.transactions({id: this.$route.params.id}).then((r) => {
      this.transactions = r.data;
    })
  }
}
</script>

<style scoped>

</style>
