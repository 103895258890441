<template>
  <v-card outlined class="my-2">
    <v-card-text>
      <h4>Права пользователя</h4>
      <v-treeview v-if="permissions"
                  selectable
                  :items="permissions"
                  open-all
                  @input="update"
                  item-text="description"
                  item-key="key"
                  v-model="permissions_value"
      ></v-treeview>
    </v-card-text>

  </v-card>
</template>

<script>


import user from "@/api/user.js";

export default {
  name: "PermissionsComponent",
  props: ['user_id'],
  methods: {
    update() {
      alert()
      user.permissions_update(this.user_id, this.permissions_value).then((r) => {
        this.permissions_value = r.data;
      })
    }
  },
  data() {
    return {
      permissions: null,
      permissions_value: [],
    }
  },
  mounted() {
    user.permissions(this.user_id).then((r) => {
      this.permissions = r.data.tree;
      this.permissions_value = r.data.permissions;
    })
  }
}
</script>

<style scoped>

</style>
